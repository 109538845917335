import { IonPage, IonContent, IonButton} from '@ionic/vue';
import PageHeader from '@/components/page-header';
export default {
    components: {
        IonPage,
        IonContent,
        IonButton,
        PageHeader
    },
    data() {
        return {
            tabs: null,
            tabHtml: "",
            activeTabId: null,
            firstMount: true,
            loadTrigger: 0
        }
    },
    inject: ["contentService"],
    mounted() {
        this.init();
    },

    ionViewDidEnter() {
        if (this.firstMount) {
            this.firstMount = false;
        } else {
            this.init();
        }
    },

    computed: {
        enrichedTabs: function () {
            var result = [];
            if (this.tabs) {
                result = this.tabs.map(row => {
                    return {
                        tab: row,
                        class: this.tabClass(row.id)
                    }
                });
            }
            return result;
        }
    },
    methods: {
        init: function () {
            this.loadTrigger++;
            this.contentService.getItems("Information").then(x => {
//console.log("contentItems", x.contentItems);
                this.tabs = x.contentItems;
                this.selectTab(x.contentItems[0].id);
            })
        },
        tabClass: function (tabId) {
            return tabId == this.activeTabId ? 'active' : '';
        },
        selectTab(tabId/*: number*/)/*: void*/ {
            this.activeTabId = tabId;
            this.contentService.getItem(tabId).then(x => {
                this.tabHtml = x.description;
            })
        }
    }

}
