<ion-page>
    <page-header :loadTrigger='loadTrigger'></page-header>
    <ion-content>          
        <div class="menulist">
            <ion-button v-for='row in enrichedTabs' class="nav-item" :class="row.class" @click="selectTab(row.tab.id)" v-text="row.tab.title">                
            </ion-button>
        </div>         
        <div class="clear"></div>
        <div class='main' v-html="tabHtml"></div>
    </ion-content>
</ion-page>